import React from 'react'

import Layout            from '../components/layout'
import GoodNewsHeroImage from '../components/images/good-news-hero-image'
import HeroWidget        from '../components/hero-widget';
import ButterPageContent from '../components/butter-page-content';
import Footer            from '../components/footer'

import styles from './about.module.scss';

const Page = () => (
	<Layout page="about">
		<div className={styles.page}>

			<HeroWidget image={GoodNewsHeroImage}>
				<h1>The Good News</h1>
			</HeroWidget>

			<section>
				<ButterPageContent slug='the-good-news'/>
			</section>

			<Footer butter="true"/>
		</div>
	</Layout>
)

export default Page;
